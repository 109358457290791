module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-170124411-1","head":true,"anonymize":true,"respectDNT":true,"exclude":["/preview/**","/blog/preview/**"],"pageTransitionDelay":0,"defer":false,"siteSpeedSampleRate":10,"cookieDomain":"safetylogz.com"},
    },{
      plugin: require('../node_modules/gatsby-source-prismic/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"safetylogz","accessToken":"MC5YdXNfYmhRQUFFd2dxaS04.Y--_vURdFu-_ve-_vRNl77-9YlsQd--_ve-_vV7vv73vv73vv73vv73vv71a77-9Ce-_ve-_ve-_ve-_ve-_ve-_vQw","schemas":{"blog_post":{"Main":{"uid":{"type":"UID","config":{"label":"slug","placeholder":"slug"}},"author":{"type":"Text","config":{"label":"author","placeholder":"Author"}},"author_image":{"type":"Image","config":{"constraint":{"width":300,"height":300},"thumbnails":[],"label":"Author image"}},"category":{"type":"Text","config":{"label":"category","placeholder":"Category"}},"article_title":{"type":"StructuredText","config":{"single":"heading2","label":"Article Title"}},"article_post_date":{"type":"Date","config":{"label":"Article post date","placeholder":"Article Written Date"}},"heading_image":{"type":"Image","config":{"constraint":{"width":1200,"height":450},"thumbnails":[],"label":"Article Heading Image"}},"paragraph":{"type":"StructuredText","config":{"multi":"paragraph, preformatted, heading1, heading2, heading3, heading4, heading5, heading6, strong, em, hyperlink, image, embed, list-item, o-list-item, o-list-item","allowTargetBlank":true,"label":"Paragraph","placeholder":"Paragraph"}}}}},"imageImgixParams":{"auto":"compress,format","fit":"max","q":50}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#eff4f5","theme_color":"#eff4f5","display":"minimal-ui","icon":"src/images/safetylogz-icon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"0e8ccc0115fce9a9206ba637a8c85030"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
